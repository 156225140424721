var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import $ from "jquery";
import prestashop from "prestashop";
prestashop.cart = prestashop.cart || {};
prestashop.cart.active_inputs = null;
const spinnerSelector = 'input[name="product-quantity-spin"]';
let hasError = false;
let isUpdateOperation = false;
let errorMsg = "";
const CheckUpdateQuantityOperations = {
  switchErrorStat: () => {
    const $checkoutBtn = $(".checkout a");
    if ($("#notifications article.alert-danger").length || errorMsg !== "" && !hasError) {
      $checkoutBtn.addClass("disabled");
    }
    if (errorMsg !== "") {
      const strError = `<article class="alert alert-danger" role="alert" data-alert="danger"><ul><li>${errorMsg}</li></ul></article>`;
      $("#notifications .container").html(strError);
      errorMsg = "";
      isUpdateOperation = false;
      if (hasError) {
        $checkoutBtn.removeClass("disabled");
      }
    } else if (!hasError && isUpdateOperation) {
      hasError = false;
      isUpdateOperation = false;
      $("#notifications .container").html("");
      $checkoutBtn.removeClass("disabled");
    }
  },
  checkUpdateOpertation: (resp) => {
    hasError = Object.prototype.hasOwnProperty.call(resp, "hasError");
    const errors = resp.errors || "";
    if (errors instanceof Array) {
      errorMsg = errors.join(" ");
    } else {
      errorMsg = errors;
    }
    isUpdateOperation = true;
  }
};
function createSpin() {
  $.each($(spinnerSelector), (index, spinner) => {
    $(spinner).TouchSpin({
      verticalupclass: "material-icons touchspin-up",
      verticaldownclass: "material-icons touchspin-down",
      buttondown_class: "btn btn-touchspin js-touchspin js-increase-product-quantity",
      buttonup_class: "btn btn-touchspin js-touchspin js-decrease-product-quantity",
      min: parseInt($(spinner).attr("min"), 10),
      max: parseInt($(spinner).attr("max"), 10)
    });
    if ($(spinner).val() == $(spinner).attr("max")) {
      $(spinner).parent().find(".bootstrap-touchspin-up").addClass("disabled").prop("disabled", true);
    } else {
      $(spinner).parent().find(".bootstrap-touchspin-up").removeClass("disabled").prop("disabled", false);
    }
  });
  CheckUpdateQuantityOperations.switchErrorStat();
}
$(() => {
  const productLineInCartSelector = ".js-cart-line-product-quantity";
  const promises = [];
  prestashop.on("updateCart", () => {
    $(".quickview").modal("hide");
    $("body").addClass("cart-loading");
  });
  prestashop.on("updatedCart", () => {
    createSpin();
    $("body").removeClass("cart-loading");
    if ($(".cart-container").length > 1) {
      $(".cart-grid").addClass("cart-grid-multi");
    } else {
      $(".cart-grid").removeClass("cart-grid-multi");
    }
    if ($("#notifications article.alert-danger").length) {
      $(".checkout a").addClass("disabled");
    }
  });
  createSpin();
  const $body = $("body");
  function isTouchSpin(namespace) {
    return namespace === "on.startupspin" || namespace === "on.startdownspin";
  }
  function shouldIncreaseProductQuantity(namespace) {
    return namespace === "on.startupspin";
  }
  function findCartLineProductQuantityInput($target) {
    const $input = $target.parents(".bootstrap-touchspin").find(productLineInCartSelector);
    if ($input.is(":focus")) {
      return null;
    }
    return $input;
  }
  function camelize(subject) {
    const actionTypeParts = subject.split("-");
    let i;
    let part;
    let camelizedSubject = "";
    for (i = 0; i < actionTypeParts.length; i += 1) {
      part = actionTypeParts[i];
      if (i !== 0) {
        part = part.substring(0, 1).toUpperCase() + part.substring(1);
      }
      camelizedSubject += part;
    }
    return camelizedSubject;
  }
  function parseCartAction($target, namespace) {
    if (!isTouchSpin(namespace)) {
      return {
        url: $target.attr("href"),
        type: camelize($target.data("link-action"))
      };
    }
    const $input = findCartLineProductQuantityInput($target);
    let cartAction = {};
    if ($input) {
      if (shouldIncreaseProductQuantity(namespace)) {
        cartAction = {
          url: $input.data("up-url"),
          type: "increaseProductQuantity"
        };
      } else {
        cartAction = {
          url: $input.data("down-url"),
          type: "decreaseProductQuantity"
        };
      }
    }
    return cartAction;
  }
  const abortPreviousRequests = () => {
    let promise;
    while (promises.length > 0) {
      promise = promises.pop();
      promise.abort();
    }
  };
  const getTouchSpinInput = ($button) => $($button.parents(".bootstrap-touchspin").find("input"));
  const handleCartAction = (event) => {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const { dataset } = event.currentTarget;
    const cartAction = parseCartAction($target, event.namespace);
    const requestData = {
      ajax: "1",
      action: "update"
    };
    if (typeof cartAction === "undefined") {
      return;
    }
    abortPreviousRequests();
    $.ajax({
      url: cartAction.url,
      method: "POST",
      data: requestData,
      dataType: "json",
      beforeSend: (jqXHR) => {
        promises.push(jqXHR);
      }
    }).then((resp) => {
      CheckUpdateQuantityOperations.checkUpdateOpertation(resp);
      const $quantityInput = getTouchSpinInput($target);
      $quantityInput.val(resp.quantity);
      prestashop.emit("updateCart", {
        reason: dataset,
        resp,
        cartAction: cartAction.type
      });
    }).fail((resp) => {
      prestashop.emit("handleError", {
        eventType: "updateProductInCart",
        resp,
        cartAction: cartAction.type
      });
    });
  };
  $body.on("click", '[data-link-action="delete-from-cart"], [data-link-action="remove-voucher"]', handleCartAction);
  $body.on("touchspin.on.startdownspin", spinnerSelector, handleCartAction);
  $body.on("touchspin.on.startupspin", spinnerSelector, handleCartAction);
  function sendUpdateQuantityInCartRequest(updateQuantityInCartUrl, requestData, $target) {
    abortPreviousRequests();
    return $.ajax({
      url: updateQuantityInCartUrl,
      method: "POST",
      data: requestData,
      dataType: "json",
      beforeSend: (jqXHR) => {
        promises.push(jqXHR);
      }
    }).then((resp) => {
      CheckUpdateQuantityOperations.checkUpdateOpertation(resp);
      $target.val(resp.quantity);
      let { dataset } = __spreadValues({}, $target);
      if (!dataset) {
        dataset = resp;
      }
      prestashop.emit("updateCart", {
        reason: dataset,
        resp
      });
    }).fail((resp) => {
      prestashop.emit("handleError", {
        eventType: "updateProductQuantityInCart",
        resp
      });
    });
  }
  function getQuantityChangeType($quantity) {
    return $quantity > 0 ? "up" : "down";
  }
  function getRequestData(quantity) {
    return {
      ajax: "1",
      qty: Math.abs(quantity),
      action: "update",
      op: getQuantityChangeType(quantity)
    };
  }
  function updateProductQuantityInCart(event) {
    const $target = $(event.currentTarget);
    const updateQuantityInCartUrl = $target.data("update-url");
    const baseValue = $target.attr("value");
    const targetValue = $target.val();
    if (targetValue === baseValue) {
      return;
    }
    const qty = targetValue - baseValue;
    if (targetValue === "0") {
      $target.parents(".product-line-grid__row").find('[data-link-action="delete-from-cart"]').trigger("click");
      return;
    }
    $target.attr("value", targetValue);
    sendUpdateQuantityInCartRequest(updateQuantityInCartUrl, getRequestData(qty), $target);
  }
  $body.on(
    "focusout keyup",
    productLineInCartSelector,
    (event) => {
      if (event.type === "keyup") {
        if (event.keyCode === 13) {
          updateProductQuantityInCart(event);
        }
        return false;
      }
      return updateProductQuantityInCart(event);
    }
  );
  $body.on(
    "click",
    ".js-discount .js-code",
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      const $code = $(event.currentTarget);
      const $discountInput = $("[name=discount_name]");
      const $discountForm = $discountInput.closest("form");
      $discountInput.val($code.text());
      $discountForm.trigger("submit");
      return false;
    }
  );
});
